.image-icon {
  align-self: stretch;
  flex: 3 0.1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.card-02,
.blog-content {
  display: flex;
  flex-direction: column;
}
.card-02 {
  height: 426.2px;
  /* width: 286px; */
  background-color: var(--white);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  max-width: 100%;
  text-align: left;
  /* font-size: var(--text-xs-font-bold-size); */
  color: var(--dark);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  /* font-family: var(--text-xs-font-bold); */
}
.card-02,
.image-icon {
  border-radius: 10px;
}
.blog-content {
  align-self: stretch;
  flex: 4;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-base) var(--padding-base-1);
}
.container1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.blog-title {
  position: relative;
  /* line-height: 100%; */
  font-size: 0.9rem;
  font-weight: bold;
}
.blog-snippet {
  align-self: stretch;
  font-size: 0.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.read-more {
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 68px;
  font-weight: bold;
}
.read-more-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vuesaxlineararrow-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
}
.ctalink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.blog-hero-content > .ctalink {
  margin-top: 1rem;
}

.ctalink:hover {
  color: var(--pastel-blue);
}
.ctalink:hover .arrow-icon {
  fill: var(--pastel-blue);
}

.blog-meta {
  position: relative;
  line-height: 150%;
  align-self: stretch;
}
