.carousel-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 0 10px;
}

.swiper {
  position: relative;
  width: 100%;
  padding-bottom: 65px;
  /* overflow: hidden; */
  
}

.swiper-wrapper {
  display: flex;
  width: 100%;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
}



.swiper-slide {
  flex-shrink: 0;
  width: auto;  
  height: 290px; 
  position: relative;
  transition-property: transform, opacity, z-index;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  object-fit: cover;
}



.swiper-carousel-animate-opacity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.swiper-carousel-animate-opacity img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}


.swiper-carousel-animate-opacity .slide-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  border-radius: 0 0 10px 10px;
  color: #fff;
  text-align: center;
}

.swiper-carousel-animate-opacity .slide-content h2 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

.swiper-carousel-animate-opacity .slide-content p {
  margin: 0;
  font-size: 12px;
}

.swiper-container {
  width: 100%;
  padding-bottom: 40px; 
}

.pagination-navigation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

/* .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: .3s opacity;
  transform: translate(0);
  z-index: 10;
  bottom: 10px;
  width: 100%;
} */

.swiper-pagination {
  position: static; 
  display: flex; 
  align-items: center;
  justify-content: center;
  flex: 0;
  margin: 0 10px;
}

/* .swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007aff;
} */
:root {
  --swiper-button-size: 22px;
  --swiper-button-padding: calc(var(--swiper-button-size) / 5); /* Example static calculation */
}
.swiper-button-next,
.swiper-button-prev {
  width: var(--swiper-button-size);
  height: var(--swiper-button-size);
  /* padding: var(--swiper-button-padding); */
  box-sizing: border-box;
  /* border: 1px solid var(--color-steelblue);
  border-radius: 50%; */
  color: var(--color-steelblue);
  position: static; 
  margin: 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: calc(var(--swiper-button-size) / 1.5);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  display: block;
  font-weight: 1000;
}

.slide-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(1deg, rgba(70, 130, 180, 0.60) 41.15%, rgba(30, 56, 78, 0.00) 85.56%);
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 2;
  border-radius: 10px;
}

.slide-hashtag {
  color: #fff;
  padding: 16px 16px 11px;
  font-weight: 500;
}

.slide-hashtag span {
  display: inline-block;
  border: 1.5px solid #fff; 
  padding: 5px 10px; 
  margin: 0 5px 5px 0; 
  border-radius: 25px; 
  font-size: 0.9rem;
}

.active-slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1064px;
  margin: 0 auto;
  padding: 10px 0 10px;
}

.slide-title-wrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

.active-slide-content h2 {
  font-size: 1.8rem;
  font-family: var(--text-xs-font-bold);
  font-weight: 500;
  margin: 0;
}

.active-slide-content p {
  font-size: var(--text-base-font-bold-size);
  /* font-family: var(--text-3xl-font-semibold); */
  margin: 0;
  max-width: 900px;
}

.svg-link {
  display: inline-block;
  cursor: pointer;
}

.svg-link:hover .svg-icon rect {
  fill: var(--pastel-blue);
}

.svg-icon {
  width: 34px;
  height: 34px;
  transition: fill 0.3s ease;
}

@media (max-width: 1439px) {
  .swiper-slide {
    height: calc(0.23684 * (100vw - 376px) + 44.401px);
  }
}

@media (max-width: 1249px) {
  .swiper-slide {
    height: calc(0.25 * (100vw - 376px) + 27.5px);
  }
}

@media (max-width: 1149px) {
  .swiper-slide {
    height: calc(0.19841 * (100vw - 376px) + 67.812px);
  } 
}

@media (max-width: 1023px) {
  .swiper-slide {
    height: 195px; 
  }
}

@media (max-width: 639px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}
