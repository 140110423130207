.title {
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 150%;
    font-weight: 700;
    font-family: inherit;
  }

  .description {
    align-self: stretch;
    position: relative;
    font-size: var(--text-base-font-bold-size);
    line-height: 150%;
    display: flex;
    flex-direction: column;
    gap: var(--gap-base);
  }
  .about-info,
  .info-container {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  .info-container {
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .about-info {
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
  }
  .info-data {
    position: relative;
    line-height: 150%;
    display: inline-block;
  }
  .years-of-experience {
    position: relative;
    line-height: 130%;
  }
  .info-01,
  .years-of-experience-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .years-of-experience-wrapper {
    flex-direction: row;
    font-size: var(--text-lg-font-bold-size);
    color: var(--white);
  }
  .info-01 {
    flex: 1;
    flex-direction: column;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
    min-width: 203px;
  }
  .b {
    position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 64px;
  }
  .years-of-experience1 {
    position: relative;
    line-height: 130%;
  }
  .info-02,
  .years-of-experience-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .years-of-experience-container {
    flex-direction: row;
    font-size: var(--text-lg-font-bold-size);
    color: var(--white);
  }
  .info-02 {
    flex-direction: column;
    padding: 0 63px;
  }
  .b1 {
    position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 76px;
  }
  .years-of-experience2 {
    position: relative;
    line-height: 130%;
  }
  .info-03,
  .years-of-experience-frame {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .years-of-experience-frame {
    flex-direction: row;
    font-size: var(--text-lg-font-bold-size);
    color: var(--white);
  }
  .info-03 {
    flex: 1;
    flex-direction: column;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
    min-width: 203px;
  }
  .about-content,
  .container {
    display: flex;
    align-items: flex-start;
  }
  .container {
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    gap: 64px;
    font-size: var(--font-size-45xl);
    color: var(--grey-blue);
  }
  .about-content {
    width: 1064px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    flex-shrink: 0;
    max-width: 100%;
  }
  /* .background {
    align-self: stretch;
    width: 234px;
    position: relative;
    border-radius: 50%;
    background-color: var(--color-steelblue);
    flex-shrink: 0;
  } */
  /* .about-background {
    width: 1064px;
    height: 234px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    flex-shrink: 0;
  } */
  .about,
  .root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* box-sizing: border-box; */
  }
  .about {
    align-self: stretch;
    background-color: #333;
    flex-shrink: 0;
    align-items: center;
    padding: var(--padding-45xl) var(--padding-xl);
    gap: 56px;
    max-width: 100%;
    text-align: center;
    font-size: var(--text-4xl-font-bold-size);
    color: var(--white);
    font-family:  var(--text-xs-font-bold);
  }
  .root {
    width: 100%;
    position: relative;
    align-items: flex-start;
    line-height: normal;
    letter-spacing: normal;
  }
  @media screen and (max-width: 1050px) {
    .title {
      font-size: var(--font-size-10xl);
      line-height: 43px;
    }
    .info-data {
      font-size: var(--font-size-32xl);
      line-height: 77px;
    }
    .info-01 {
      flex: 1;
    }
    .b,
    .b1 {
      font-size: var(--font-size-32xl);
      line-height: 77px;
    }
    .info-03 {
      flex: 1;
    }
    .container {
      flex-wrap: wrap;
      gap: var(--gap-13xl);
    }
    .about {
      height: auto;
    }
  }
  @media screen and (max-width: 750px) {
    .about-content,
    .container {
      gap: var(--gap-base);
    }
    .about {
      gap: 28px;
      padding-top: var(--padding-23xl);
      padding-bottom: var(--padding-33xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .title {
      font-size: 22px;
      line-height: 32px;
    }
    .b,
    .info-data {
      font-size: var(--font-size-19xl);
      line-height: 58px;
    }
    .info-02 {
      padding-left: var(--padding-xl);
      padding-right: var(--padding-xl);
      box-sizing: border-box;
    }
    .b1 {
      font-size: var(--font-size-19xl);
      line-height: 58px;
    }
  }