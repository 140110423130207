.contact-me-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-steelblue);
}
.contact-me-inner-section {
    max-width: 1064px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 124px 64px;
    gap: 60px;
}

.contact-me-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: var(--gap-base);
}
.contact-details {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 48px;
}
.contact-location-phone{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.contact-item {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.contact-me-form {
    flex: 1;
    padding: 0 20px;
}
.contact-me-form input,
.contact-me-form textarea {
  width: 100%; 
  box-sizing: border-box;
  border-radius: 4px; 
}
.contact-me-form input {
    height: 48px;
}
.contact-me-form textarea {
    height: 200px;
}

.contact-me-form .form-group {
  margin-bottom: 1rem; 
}
.contact-me-form input,
.contact-me-form textarea {
    padding: 16px; 
    border-radius: 4px; 
    margin-top: 5px; 
    display: inline-block; 
}
/* .contact-me-form input::placeholder,
.contact-me-form textarea::placeholder {
  color: #c1c1c1;
}  */

.contact-me-form input:focus,
.contact-me-form textarea:focus {
    /* border: 4px solid #8fc1e2;  */
    /* outline: none;  */
    outline-color:  #8fc1e2;  ;
}


.input {
    padding: 5px;
    border-radius: 4px;
    margin-top: 5px;
    display: inline-block;
}
.input-error,
.textarea.input-error {
    border-color: #FF6B6B !important;
    border-width: 2px;
    border-style: solid;
}
.input-error::placeholder {
    color: #FF6B6B; 
}
.error {
    color: #C5100A; /* Lighter shade of red or choose another contrasting color */
    background-color: #EBC7C8; /* Adding a light background color for better contrast */
    padding: 5px;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 0.875em;
    display: inline-block;
}
.contact-me-form > .error::before {
    content: "❌";
    margin-right: 5px;
} 

.contact-me-form .form-group {
    margin-bottom: 10px;
}

.contact-me-form button[type="submit"] {
    margin-top: 32px; 
    font-weight: bold;
}
