.blog-card-hero,
.blog-hero-content {
  display: flex;
  flex-direction: column;
}
.blog-card-hero {
    grid-column: span 4;
    /* height: 426.2px; */
    background-color: var(--white);
    align-items: center;
    /* gap: var(--gap-21xl-7); */
    width: 100%;
    text-align: left;
    color: var(--dark);
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    border-radius: 10px;
    /* box-sizing: border-box; */
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
  }
  .blog-card-hero > .hero-image, .blog-card-hero > .blog-hero-container {
    flex: 0 1 50%;
    box-sizing: border-box;
    min-width: 0;
    max-width: 100%;
  }
.hero-image {
    align-self: stretch;
    position: relative;
    /* max-width: 592px; */
    /* overflow: hidden; */
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: auto;
}
.blog-hero-content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    font-size: var(--font-size-base-3);
    font-family: var(--text-xs-font-bold);
    box-sizing: border-box;
}
.blog-hero-desc,
.blog-hero-title {
  position: relative;
  font-size: 1rem;
  line-height: 130%;
}
.blog-hero-title {
  font-weight: bold;
}

.blog-hero-desc {
  /* align-self: stretch; */
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-hero-container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px var(--padding-37xl) 30px var(--padding-xl-3);
  gap: var(--gap-xl-4);
  width: 100%;
}
.blog-hero-date,
.editors-pick {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.editors-pick {
  line-height: 150%;
  display: inline-block;
  min-width: 101px;
  border-radius: 25px;
  background-color: var(--pastel-blue);
  border: 0.6px solid var(--white);
  justify-content: center;
  padding: var(--padding-11xs) var(--padding-sm);
  mix-blend-mode: normal;
  white-space: nowrap;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blog-hero-meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}