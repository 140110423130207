

.navcircle {
    height: 146px;
    width: 146px;
    position: absolute;
    margin: 0 !important;
    top: -88px;
    left: -100px;
    border-radius: 50%;
    background-color: var(--pastel-blue);
  }

  .navbar,
  .title1 {
    align-self: stretch;
  }
  .container1192.flex {
    justify-content: space-between;
  }
  .navbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* padding: 0 var(--padding-xl); */
    box-sizing: border-box;
    position: sticky;
    gap: var(--gap-294xl-5);
    top: 20px; /* Moved down */
    z-index: 1000;
    max-width: 100%;
    text-align: left;
    font-size: var(--text-3xl-font-semibold-size);
    color: var(--pastel-blue);
    font-family: var(--text-3xl-font-semibold);
    background-color: steelblue;
  }
  
  .navbar::before {
    content: "";
    position: absolute;
    top: -20px; /* Offset to cover the space above the navbar */
    left: 0;
    right: 0;
    height: 22px; /* Match the moved down space */
    background-color: steelblue; /* Match the navbar background color */
    z-index: -1; /* Ensure it sits behind the navbar content */
  }


  .brand {
    width: 180px;
    opacity: 0.9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs-5) 0 0;
    box-sizing: border-box;
    /* visibility: hidden; */
  }
  
  .louis {
    margin: 0;
    align-self: stretch;
    height: 45px;
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
    font-weight: 600;
    font-family: inherit;
    /* display: inline-block; */
    white-space: nowrap;
  }
  
  .page-links {
    margin: 0;
    display: flex;
    align-items: flex-start;
    width: 400px;
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--padding-smi-5) 0 0;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
  }
  
  .about1 ,
  .blog ,
  .portfolio ,
  .services ,
  .link-style,
  .links-container {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--text-lg-font-bold-size);
    font-weight: 700;
    color: var(--dark);
    font-family: var(--text-lg-font-bold);
    text-decoration: none;
    position: relative;
    display: flex;
  }
  
  .about1:hover,
  .services:hover,
  .portfolio:hover,
  .blog:hover {
    color: var(--white);
  }

  /* .about1,
  .blog,
  .portfolio,
  .services {
    position: relative;
    line-height: 130%;
    display: inline-block;
  } */
  
  .button-nav {
    cursor: pointer;
    border: 0;
    padding: var(--padding-xs) var(--padding-5xl);
    background-color: var(--color-cta);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    white-space: nowrap;
    border-radius: 4px;
  }
  .button-nav:hover {
    background-color: var(--color-cta-hover);
  }
  

  .links-container,
.page-links {
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.links-container {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--text-lg-font-bold-size);
  color: var(--dark);
  font-family: var(--text-lg-font-bold);
}
.page-links {
  width: 360px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-smi-5) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

  .lets-talk {
    position: relative;
    font-size:  var(--text-base-font-bold-size);
    line-height: 150%;
    display: inline-block;
    font-family:  var(--text-xs-font-bold);
    color: var(--dark);
    text-align: middle;
    min-width: 79px;
  }
  

  
  