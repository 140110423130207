
.blog-landingpage-wrapper {
    max-width: 1260px;
    margin: 0 auto;
}
.blog-landingpage {
    background-color: #F7F7F7;
    font-size: var(--text-4xl-font-bold-size);
    font-family: var(--text-xs-font-bold);
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 64px 0;
}
.blog-landingpage > .section-inner-container {
    gap: 30px;
} 

.more-landingblogs-wrapper {
    height: 470px;
    display: stretch;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: hidden;
    margin: 0 auto;
}

.more-landingblogs-wrapper > .blog-position {
    padding: 30px 0;
}