.errorMessage {
    color: #C5100A;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #EBC7C8;
    padding: 5px 11px 5px 8px;
    margin-top: 5px;
    font-size: 0.9em;
    gap: 3px;
    max-width: fit-content;
  }
  

  
  .input-error {
    border-color: red;
  }
  
  