

  .myskill-content,
  .mycert-content {
    max-width: 1036px;
    width: 100%;
    text-align: left;
    font-size: var(--text-lg-font-bold-size);
    color: var(--color-steelblue);
    font-family: var(--text-xs-font-bold);
    align-self: middle;
    box-sizing: border-box;
  }

.myskill-content,
.mycert-content,
.myskill-section,
.mycert-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
.myskill-frame-group {
    align-self: stretch;
    flex-direction: row;
    gap: 30px;
    width: 100%;
  }
.services-core {
    align-self: stretch;
    border-radius: 10px;
    background-color: #8fc1e2;
    flex-direction: column;
    padding: var(--padding-base);
    color: var(--dark);
    gap: 16px;
  }
.myskill-frame-group,
.services-core {
  display: flex;
}
.myskill-frame-container {
    /* width: 274px;
    min-height: 100%; */
    display: flex;
    flex-direction: column;
    gap: var(--gap-base);
    /* min-width: 274px; */
    flex: 1;
  }
.frame-div {
  display: flex;
  flex-direction: column;
  gap: 28px;
  flex: 2 !important;
}
.frame-parent1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.core-supporting-skill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 75px;
}
.title-core {
    position: relative;
    line-height: 130%;
    display: inline-block;
    min-width: 123px;
    color: var(--color-steelblue);
    font-size: var(--chakra-fontSizes-2xl);
  }

.services-core-inner,
.vector-ITBA {
    align-self: stretch;
    display: flex;
    justify-content: center;
}
.vector-ITBA {
    flex-direction: row;
    align-items: center;
    padding: 0 var(--padding-12xs) 0 0;
    gap: var(--gap-3xs);
}
.vector-ITBA path {
    stroke: var(--white);
}
.title-ITBA {
    position: relative;
    line-height: 130%;
    color: var(--white);
    font-size: var(--chakra-fontSizes-2xl);
  }
.services-core-inner {
    flex-direction: column;
    align-items: flex-start;
  }

.description-ITBA {
    /* align-self: stretch; */
    /* position: relative; */
    font-size: var(--text-base-font-bold-size);
    line-height: 150%;
    /* text-align: justify; */
    color: #fff;
}
.description-ITBA p {
  margin-bottom: 16px;
}
.myskill-section,
.mycert-section {
    width: 100%;
    position: relative;
    overflow: hidden;
    align-items: center;
    padding: 64px 124px 0 124px;
    box-sizing: border-box;
    gap: 50px;
    line-height: normal;
    letter-spacing: normal;
  }
  .mycert-section {
    padding-bottom: 64px !important;
  } 
  .certifications {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-xl);
    font-size: var(--text-base-font-bold-size);
    color: var(--dark);
  }

  .cert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
  }
  .cert:hover{
    font-weight: bold;
  }
  .certification-icons {
    width: 100px;
    height: 100px;
    position: relative;
    object-fit: contain;
    mix-blend-mode: luminosity;
  }
  .certification-icons:hover {
    mix-blend-mode: normal;
  }
  .cert-description {
    position: relative;
    line-height: 150%;
  }
  .cert-description p {
    margin: 0;
    text-align: center;
    white-space: nowrap;
  }
  .services-section {
    display: flex;
    flex-direction: column;
    /* width: 1440px; */
    /* padding: 64px 124px 128px 124px; */
    justify-content: center;
    align-items: center;
    gap: 150px;
    background-color: var(--whiteblue-background);
    padding-bottom: 128px;
    
  }

  @media (max-width: 1100px) {
    .certifications {
      justify-content: center;
    }
  }
    



  