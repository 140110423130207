/* .blog-position {
  background-color:var(--gray-blue);
  display: flex; 
  justify-content: center; 
  align-items: center; 
  padding: 20px 0; 
  flex-direction: column; 
} */

.blog-position {
  background-color:var(--gray-blue); 
  padding: 10px 0; 
}
/* .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--text-xs-font-bold-size);
  color: var(--dark);
  font-family: var(--text-xs-font-bold);
} */

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
  gap: 1rem;
  justify-content: center;
  align-items: start;
  /* max-width: 1250px; */
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: var(--text-xs-font-bold-size);
  color: var(--dark);
  font-family: var(--text-xs-font-bold);
}