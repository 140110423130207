.footer {
    background: #333;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
  }
  
  .newsletter-container {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .newsletter-heading {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .newsletter-subheading {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 8px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
  }

 .newsletter-container input {
    padding: 16px; 
    border-radius: 4px; 
    display: inline-block; 
    color: #000;
    height: 44px;
}

  .message {
    background-color: #EBC7C8; 
    padding: 5px 11px 5px 8px;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 0.9em;
    gap: 3px;
    max-width: fit-content;
  }
  
  .error-message::before{
    margin-right: 5px;
  }


  .input-group {
    display: flex;
    align-items: center;
    flex-direction: row;
}

  .input {
    padding: 10px;
    width: 250px;
    margin-right: 10px;
    margin-top: 0;
    border: none;
    border-radius: 4px;
    color: #000; 
  }

  .success {
    color: green;
    display: flex;
    background-color: #DFF0D8; 
  }

  .success > .icon,
  .error > .icon {
    font-size: 1.3em;
    margin: 0 3px 0 0;
    align-content: center;
    mix-blend-mode: normal;
  }

  .success > .icon {
    color: green;
  }
  .error > .icon {
    color: red;
  }


  .error {
    display: flex;
    width: 100%;
  }


  
  .button {
    padding: 10px 20px;
    background: var(--color-cta);
    color: #333;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .button:hover {
    background: var(--color-cta-hover);
  }
  
  .privacy-text {
    font-size: 0.9em;
    display: flex;
    gap: 5px;
  }
  
  .privacy-link {
    color: #fff;
    text-decoration: underline;
    margin-left: 5px;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    padding-top: 20px;
  }
  
  .nav {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .link {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
    font-weight: bold;
  }
  
  .social-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  
  .follow-me {
    font-size: 1.2em;
    margin-right: 10px;
  }
  
  .icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icon {
    width: 24px;
    height: 24px;
    margin: 0 5px;
    mix-blend-mode: luminosity;
  }
  .social-icon:hover {
    mix-blend-mode: normal;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    margin: 0 5px;
  }
  
@media screen and (max-width: 454px) {
    .newsletter-subheading {
        width: 300px;
    }
}