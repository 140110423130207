
.blog-page-wrapper {
    max-width: 1064px;
    margin: 0 auto;
}
.blog-page {
    position: relative; /* Add this line */
    /* height: calc(100vh - 60px); */
    margin-top: 0px;
    /* overflow: auto; */
    background-color: var(--color-steelblue);
    background-image: linear-gradient(to bottom, steelblue, lightsteelblue);
    padding: 80px 20px 130px 20px ; 
    box-sizing: border-box;
    min-height: 100vh;
    width: 100vw;
    font-size: var(--text-4xl-font-bold-size);
    font-family: var(--text-xs-font-bold);
    gap: 40px;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    align-items: center;
    display: flex;
    flex-direction: column;
}

.here-i-share,
.blog-page-title,
.welcome-to-my-blog {
  margin: 0;
}
.blog-page-heading {
    width: 1000px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    gap: var(--gap-base);
    max-width: 100%;
    color: var(--pastel-blue);
}
.blog-page-title {
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 150%;
    font-weight: 700;
    font-family: inherit;
}
.blog-page-description {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-xl);
    line-height: 150%;
}
.more-blog-text {
    position: relative;
    font-size: var(--font-size-xl-4);
    line-height: 31px; 
    padding: 60px 0 0 0;
    width: 100%;
    box-sizing: border-box;
}
.more-blog-wrapper {
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.grid-container {
    display: grid;
    gap: 20px;
    padding: 20px;
  }
