/* LandingPage.css */
.shape-117-icon {
  position: absolute;
  top: 160px;
  left: 114.2px;
  width: 25%;
  height: auto;
}
.ba,
.a {
  display: inline-block;
  text-shadow: 3.5px 0 0#a9d6e5, 0 3.5px 0#a9d6e5, -3.5px 0 0#a9d6e5,
    0-3.5px 0#a9d6e5;
}
.ba {
  /* height: 460.4px; */
  position: relative;
  max-width: 100%;
  opacity: 50%;
  z-index: 1;
}
.a {
  height: 460.5px;
  width: 302.7px;
  position: absolute;
  margin: 0 !important;
  right: 94.2px;
  bottom: -0.1px;
  color: var(--pastel-blue);
  z-index: 2;
}
.BAtext {
  z-index:2;
}

.empty-shape-container {
  position: relative;
  height: 100%;
  /* top: 0;
  bottom: 0; 
  left: 0px; */
  width: 457.4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 3;
}
.shape-two-container {
  height: auto;
  width: 68%;
  position: absolute;
  /* margin: 0 !important;
  top: -23.8px;
  left: 106px; */
}

.experience {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.shape-73-icon {
  position: absolute;
  top: 106.7px;
  left: 0;
  width: 25%;
  height: auto;
}
.hero-picture {
  position: relative;
  left: -18px;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  display: flex;
  z-index: 3;
}
.empty-shape-icon {
  position: absolute;
  top: 382.4px;
  left: 342px;
  width: 46.4px;
  height: 93.7px;
  object-fit: contain;
  z-index: 4;
}


.shape-content {
  align-self: stretch;
  height: 460.4px;
  position: relative;
  max-width: 100%;
}
.shape-content-wrapper {
  width: 687px;
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  box-sizing: border-box;
  /* min-width: 687px; */
  max-width: 100%;
  /* font-size: var(--font-size-456xl-2);
  color: var(--color-gray-100);
  font-family: var(--text-3xl-font-semibold); */
}
.content,
.hero,
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
}
.main-content {
  width: 1192px;
  align-items: flex-end;
  gap: var(--gap-29xl);
}
.content,
.hero {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
}
.hero {
  background-color: var(--color-steelblue);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-21xl) 0 0; 
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--dark);
  font-family:  var(--text-xs-font-bold);
  overflow: hidden;
}

.main-content {
  width: 1192px;
  align-items: flex-end;
  gap: var(--gap-29xl);
}

.ctas,
.header-content {
  justify-content: center;
  /* padding: 0 var(--padding-32xl) 0 0; */
  gap: var(--gap-xl);
}
.ctas {
  width: 100%;
}
.ctas > .button1,
.ctas > .button2 {
  flex-grow: 1;
  padding: 12px 24px;
  justify-content: center;
}
.header-content {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-45xl) 0 var(--padding-45xl);
  box-sizing: border-box;
  gap: var(--gap-29xl);
  /* min-width: 297px; */
  max-width: 100%;
}


.title1 {
  position: relative;
  line-height: 150%;
}


.hi-there-im,
.louis1 {
  margin: 0;
}
.louis1 {
  color: var(--pastel-blue);
}
.description1,
.title2 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
}
.title2 {
  margin: 0;
  font-size: var(--text-5xl-font-bold-size);
  font-weight: 700;
  font-family: inherit;
}
.description1 {
  align-self: stretch;
  position: relative;
  line-height: 150%;
  /* font-size:  var(--text-base-font-bold-size); */
}
.section-heading {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.contact-me {
  position: relative;
  font-size:  var(--text-base-font-bold-size);
  line-height: 150%;
  display: inline-block;
  font-family:  var(--text-xs-font-bold);
  color: var(--dark);
  text-align: left;
  min-width: 97px;
}

.ctas,
.button1,
.button2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  z-index: 100;
}

.button1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-37xl);
  background-color: var(--color-cta);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}
.portfolio1 {
  position: relative;
  font-size:  var(--text-base-font-bold-size);
  line-height: 150%;
  display: inline-block;
  font-family:  var(--text-xs-font-bold);
  color: var(--dark);
  text-align: left;
  min-width: 73px;
}
.button2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xs) var(--padding-37xl);
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.button2:hover {
  background-color: var(--color-gainsboro);
  /* border: 1px solid var(--color-dimgray); */
  box-sizing: border-box;
}
.button1:hover {
  background-color: var(--color-cta-hover);
  /* border: 1px solid var(--color-dimgray); */
  box-sizing: border-box;
}



@media (max-width: 1150px) {
  .shape-two-container{
      width: 72%; /* Make both containers full width on smaller screens */
  }
  .hero-picture {
    left: -38px;
  }
  .shape-73-icon, .shape-117-icon {
    top: 170px;
  }
  .shape-117-icon {
    left: 87px;
  }
}

@media (max-width: 1150px) {
  .shape-two-container{
    width: 80%; /* Make both containers full width on smaller screens */
  }
  .hero-picture {
    left: -45px;
  }
  .shape-117-icon {
    left: 70px;
    top: 210px;
  }
}


