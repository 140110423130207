@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-xs-font-bold: Montserrat;
  --text-3xl-font-semibold: Inter;

  /* font sizes */
  --text-xs-font-bold-size: 12px;
  --text-sm-font-normal-size: 14px;
  --text-base-font-bold-size: 16px;
  --text-4xl-font-bold-size: 36px;
  --font-size-3xl: 22px;
  --font-size-10xl: 30px;
  --text-lg-font-bold-size: 18px;
  --font-size-45xl: 64px;
  --font-size-32xl: 51px;
  --font-size-19xl: 38px;
  --font-size-456xl-2: 475.2px;
  --font-size-171xl: 190px;
  --font-size-100xl: 119px;
  --text-5xl-font-bold-size: 48px;
  --font-size-xl: 20px;
  --text-3xl-font-semibold-size: 30px;
  --font-size-mid-8: 17.8px;
  --font-size-xl-4: 20.4px;
  --font-size-base-3: 15.3px;

  /* Colors */
  --dark: #1f1f1f;
  --color-gray-100: rgba(255, 255, 255, 0.5);
  --color-steelblue: #4682b4;
  --color-2ndblue: #8fc1e2;
  --white: #fff;
  --pastel-blue: #a9d6e5;
  --color-gainsboro: #e6e6e6;
  --whiteblue-background: #f5f9fc;
  --color-dimgray: #525252;
  --color-orangered: rgba(252, 51, 20, 0);
  --bluehover: #a3c1da99;
  --color-cta: #FFD700;
  --color-cta-hover: #FFC107;

  /* Gaps */
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-29xl: 48px;
  --gap-xl: 20px;
  --gap-294xl-5: 313.5px;
  --gap-138xl: 157px;
  --gap-20xl: 39px;
  --gap-59xl: 78px;
  --gap-21xl-7: 40.7px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-base: 16px;
  --padding-base-1: 16.1px;
  --padding-5xl: 24px;
  --padding-45xl: 64px;
  --padding-xl: 20px;
  --padding-61xl: 80px;
  --padding-23xl: 42px;
  --padding-33xl: 52px;
  --padding-21xl: 40px;
  --padding-9xs-1: 3.1px;
  --padding-32xl: 51px;
  --padding-6xl: 25px;
  --padding-3xs: 10px;
  --padding-xs: 12px;
  --padding-37xl: 56px;
  --padding-smi-5: 12.5px;
  --padding-11xs-5: 1.5px;
  --padding-xl-3: 20.3px;
  --padding-11xs: 2px;
  --padding-sm: 14px;
}

.container1440 {
  max-width: 1440px;
  width: 100%;
  /* margin: 0 auto; */
  padding: 0 var(--padding-base);
}
.container1192 {
  max-width: 1192px;
  width: 100%;
  /* margin: 0 auto; */
  padding: 0 var(--padding-base);
  box-sizing: border-box;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.full-width {
  width: 100vw; 
  max-width: 100%;
  box-sizing: border-box; 
}

.section-inner-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 0 var(--padding-45xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--text-4xl-font-bold-size);
  color: var(--color-steelblue);
  font-family: var(--text-xs-font-bold);
}
.section-header {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xl);
  max-width: 1064px;
  display: flex;
  flex-direction: column;
}
.whitetext {
  color: var(--white) !important;
}
.section-title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--text-4xl-font-bold-size);
  line-height: 150%;
  font-weight: 700;
  font-family: inherit;
}


.section-description {
  align-self: stretch;
  position: relative;
  font-size: var(--text-base-font-bold-size);
  line-height: 150%;
  color: var(--dark);
  gap: var(--gap-base);
  display: flex;
  flex-direction: column;
}


.button-normal {
  cursor: pointer;
  padding: var(--padding-xs) 24px;
  font-size: var(--text-base-font-bold-size);
  font-family: inherit;
  line-height: 150%;
  background-color: var(--white);
  text-align: center ;
  border: 1px solid var(--color-steelblue);
  border-radius: 4px;
  z-index: 100;
  color: var(--color-steelblue);
}
.button-normal:hover {
  background-color: var(--whiteblue-background);
  /* border: 1px solid var(--color-dimgray); */
  box-sizing: border-box;
}