.portfolio-section {
    width: 100%;
    position: relative;
    overflow: hidden;
    align-items: center;
    padding: 64px 124px 128px 124px;
    box-sizing: border-box;
    gap: 50px;
    line-height: normal;
    letter-spacing: normal;
  }

  .portfolio-section-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 383px;
    z-index: -1;
    background-color: var(--color-steelblue);
  }